import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import PageHeader from '../../Components/PageHeader/pageHeader.component'
import ScrollAnimation from 'react-animate-on-scroll'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ReactHtmlParser from 'react-html-parser'
import './newsSingle.styles.css'

const NewsSingle = (props) => {
  console.log(props, 'sasasasasasa')
  const { pageHeader, title, descriprion } = props.location.state

  return (
    <div>
      <PageHeader data={{ title: 'News', breadcrumb: 'News -> ' + title }} />
      <Container fluid className='mt-3 mb-5'>
        <div className='item'>
          <Row>
            <Col md='12'>
              <Col md='6' className='pull-right'>
                <ScrollAnimation animateIn='fadeIn'>
                  <div className='img-block mb-3'>
                    <LazyLoadImage
                      src={pageHeader?.image}
                      alt=''
                      width='100%'
                      effect='blur'
                    />
                  </div>
                </ScrollAnimation>
              </Col>
              <div className='content-block'>
                <ScrollAnimation animateIn='fadeIn'>
                  {/* <div className="title text-left">
                                        <h2>{title}</h2>
                                    </div> */}
                  <div className='project-heading d-flex align-items-end mt-3 title text-left pt-1 ml-3'>
                    <h1 style={{ color: 'rgb(24, 30, 64)' }}>{title}</h1>
                  </div>
                  <div className='text-left m-3'>
                    {ReactHtmlParser(descriprion)}
                  </div>
                </ScrollAnimation>
              </div>
            </Col>
          </Row>
        </div>
        {/* <Row>
                    <Col className='text-left mt-5 mb-5' >
                        <h2 className="text-white">{title}</h2>
                        <img className="news-img" src={pageHeader.image} alt='news'></img>
                        <p >
                            {
                                ReactHtmlParser(descriprion)
                            }
                        </p>
                    </Col>
                </Row> */}
      </Container>
    </div>
  )
}
export default NewsSingle
