import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import './lastProject.css'

import imgAboutUs from '../images/aboutUsSection.jpg'
import imgProject1 from '../images/Seazen.jpg'
import imgProject2 from '../images/ICity-new-cairo-1.jpg'
import imgProject3 from '../images/project3.png'
import imgProject4 from '../images/ICity-new-cairo-2.jpg'
import imgProject5 from '../images/tower.jpg'

class HomeProjects extends Component {
  render() {
    return (
      <div>
        <div className='text-center page-header-container pt-5 mb-5 '>
          <p className='dark-blue-color fs-2 bottom-border'>
            Our latest projects
          </p>
        </div>

        <div class='parent'>
          <div class='div1'>
            <div className='latest-card'>
              <img className='card-img' src={imgProject1} alt='...' />
            </div>
          </div>
          <div class='div2'>
            <div className='latest-card  d-flex    c1 p-0   '>
              <div className='card-body my-auto'>
                <p className='card-title text-white'>Seazen North Coast</p>
                <p className='card-text'>
                  A unique blend of elegance, comfort, and peace of mind. It
                  caters to the needs and interests of all family members and
                  serves as the perfect escape. Seazen offers a rich experience
                  and breathtaking scenery.
                </p>
              </div>
            </div>
          </div>
          <div class='div3'>
            <div className='latest-card    card2'>
              <img className='card-img' src={imgProject2} alt='...' />
            </div>
          </div>
          <div class='div4'>
            <div className='latest-card d-flex    card2  c3 p-0  '>
              <div className='card-body my-auto'>
                <p className='card-title dark-blue-color '>Icity New Cairo</p>
                <p className='card-text dark-blue-color'>
                  At I City, we have created an environment that gives you the
                  possibility to indulge in everything you wish for, without any
                  compromise.
                </p>
              </div>
            </div>
          </div>
          <div class='div5'>
            <div className='latest-card    card2'>
              <img className='card-img' src={imgProject4} alt='...' />
            </div>
          </div>
          <div class='div6'>
            <div className='latest-card d-flex     c1 p-0  '>
              <div className='card-body my-auto'>
                <p className='card-title text-white'>4t4 Tower</p>
                <p className='card-text'>
                  4t4 is a commercial, medical, and office spaces mixed-use
                  development in the heart of the New Administrative Capital. It
                  is a dream-like reality that provides you with the perfect
                  environment for your upcoming step.
                </p>
              </div>
            </div>
          </div>
          <div class='div7'>
            <div className='latest-card   '>
              <img className='card-img' src={imgProject5} alt='...' />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HomeProjects
