import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import PageHeader from '../../Components/PageHeader/pageHeader.component'
import Careers from '../../Components/Careers/careers.component'
import TopImage from './images/Our-Career.jpg'
class AboutDma extends Component {
  constructor() {
    super()
    this.state = {
      pageHeader: {
        title: 'Careers',
        breadcrumb: 'Careers',
      },
    }
  }

  render() {
    return (
      <div>
        <PageHeader data={this.state.pageHeader} />
        {/* <Row></Row> */}
        <Container className='my-5 '>
          <Row>
            <Col md={6} className='order-0 order-md-1 mb-5'>
              <img
                src={TopImage}
                alt='Overview'
                style={{ height: '60vh', width: '100%' }}
              />
            </Col>

            <Col md={6} className='order-1 order-md-0 '>
              <Row>
                <Col md={8}>
                  <Row className={'text-left'}>
                    <Col md={6}>
                      <h3>
                        <a className={'href active'} href={'/#careers'}>
                          {'Open Positions'}
                        </a>
                      </h3>
                    </Col>
                    <Col md={6}>
                      <h3>
                        <a className={'href'} href={'/#ApplicationForm'}>
                          {'Application Form'}
                        </a>
                      </h3>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className={'pt-5'}>
                <Col md={12} className={'text-left'}>
                  <p className={'h5 text-justify dark-blue-color'}>
                    We're always looking for new talent to join the DMA
                    community and help change the world.
                    <br /> We seek talented individuals who have technical as
                    well as people skills, individuals who will both fit and
                    enhance our corporate culture with their experience,
                    aspirations, team spirit, and genuine enthusiasm. <br />
                    After all, we can only be good as the people we hire.
                  </p>
                </Col>
              </Row>

              <Row className={'pt-5'}>
                <Col md={8}>
                  <div className={'table-responsive'}>
                    <table className={'table text-left'}>
                      <thead>
                        <tr>
                          <th
                            className={'text-left page-header-container h4'}
                            style={{ color: '#d98032', borderTop: 0 }}
                          >
                            Position
                          </th>
                          <th
                            className={'text-left page-header-container h4'}
                            style={{ color: '#d98032', borderTop: 0 }}
                          >
                            Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <Careers />
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default AboutDma
