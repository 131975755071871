import React from 'react'
import Slider from 'react-slick'
import 'react-lazy-load-image-component/src/effects/blur.css'
import './slider.styles.css'

const SimpleSlider = (props) => {
  return (
    <Slider
      speed={900}
      dots={true}
      infinite={true}
      autoplay={true}
      slidesToShow={1}
      slidesToScroll={1}
      className='slider'
    >
      {props.sliderData.map((data) => {
        const { id, image, description, link } = data

        return (
          <div key={id} className='a-slide slide-item'>
            <img src={image} alt='slide' className='img-fluid sliderImg' />
            <div className='slide-text'>
              <h4 className='slide-text-h4'> {description} </h4>
              <div className='btn-container'>
                {link ? (
                  <h4>
                    <a href={link} className='btn-explore'>
                      Explore
                    </a>
                  </h4>
                ) : null}
              </div>
            </div>
          </div>
        )
      })}
    </Slider>
  )
}
export default SimpleSlider
