import React, { Component } from 'react'
import Slider from 'react-slick'
import './sliderCustomPaging.styles.css'

class SliderCustomPaging extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nav1: null,
      nav2: null,
      images: props.data.images,
      isOpen: false,
    }
  }

  handleShowDialog = (event) => {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
    let imgSrc = event.target.src
    document.querySelector('.image').src = imgSrc
    if (isOpen) document.querySelector('.dialog').style.display = 'block'
    else document.querySelector('.dialog').style.display = 'none'
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    })
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    return (
      <Slider {...settings}>
        {this.state.images.map(function (slide) {
          return (
            <div key={slide}>
              <h3>
                <img className='img-fluid' src={slide} alt='slide' />
              </h3>
            </div>
          )
        })}
      </Slider>
    )
  }
}
export default SliderCustomPaging
