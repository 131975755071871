import React, { Component } from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logo from './images/logo.png'

class Header extends Component {
  constructor(props) {
    super()

    this.state = {
      activePage: '',
    }
  }

  componentDidMount() {
    switch (window.location.pathname) {
      case '/aboutDma':
        this.setState({ activePage: 'about' })
        break
      case '/projects':
        this.setState({ activePage: 'projects' })
        break
      case '/careers':
        this.setState({ activePage: 'careers' })
        break
      case '/ApplicationForm':
        this.setState({ activePage: 'careers' })
        break
      case '/news':
        this.setState({ activePage: 'news' })
        break
      case '/contactUs':
        this.setState({ activePage: 'contact' })
        break
      default:
        break
    }
  }

  render() {
    return (
      // <Navbar collapseOnSelect expand='lg' sticky='top' variant='light'>
      //   <Container>
      //     <Navbar.Brand href='/' className='logo'>
      //       <img width='150' alt='DMA Design Consultants' src={logo} />
      //     </Navbar.Brand>

      //     <Navbar.Toggle aria-controls='responsive-navbar-nav' />

      //     <Navbar.Collapse id='responsive-navbar-nav'>
      //       <Nav className='me-auto'>
      //         <Nav.Item>
      //           <Link
      //             to={'/aboutDma'}
      //             className={
      //               'nav-link' +
      //               (this.state.activePage == 'about' ? ' active' : '')
      //             }
      //             onClick={() => this.setState({ activePage: 'about' })}
      //           >
      //             About
      //           </Link>
      //         </Nav.Item>

      //         <Nav.Item>
      //           <Link
      //             to={'/projects'}
      //             className={
      //               'nav-link' +
      //               (this.state.activePage == 'projects' ? ' active' : '')
      //             }
      //             onClick={() => this.setState({ activePage: 'projects' })}
      //           >
      //             Projects
      //           </Link>
      //         </Nav.Item>

      //         <Nav.Item>
      //           <Link
      //             to={'/careers'}
      //             className={
      //               'nav-link' +
      //               (this.state.activePage == 'careers' ? ' active' : '')
      //             }
      //             onClick={() => this.setState({ activePage: 'careers' })}
      //           >
      //             Careers
      //           </Link>
      //         </Nav.Item>

      //         <Nav.Item>
      //           <Link
      //             to={'/news'}
      //             className={
      //               'nav-link' +
      //               (this.state.activePage == 'news' ? ' active' : '')
      //             }
      //             onClick={() => this.setState({ activePage: 'news' })}
      //           >
      //             News
      //           </Link>
      //         </Nav.Item>

      //         <Nav.Item>
      //           <Link
      //             to={'/contactUs'}
      //             className={
      //               'nav-link' +
      //               (this.state.activePage == 'contact' ? ' active' : '')
      //             }
      //             onClick={() => this.setState({ activePage: 'contact' })}
      //           >
      //             Contact us
      //           </Link>
      //         </Nav.Item>
      //       </Nav>
      //     </Navbar.Collapse>
      //   </Container>
      // </Navbar>
      <Navbar collapseOnSelect expand='lg' variant='light'>
        <Navbar.Brand href='/#' className='logo'>
          <img width='150' alt='DMA Design Consultants' src={logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className=''>
            <Nav.Link
              href='/#aboutDma'
              className={
                'nav-link' + (this.state.activePage == 'about' ? ' active' : '')
              }
              onClick={() => this.setState({ activePage: 'about' })}
            >
              About
            </Nav.Link>
            <Nav.Link
              href='/#projects'
              className={
                'nav-link' +
                (this.state.activePage == 'projects' ? ' active' : '')
              }
              onClick={() => this.setState({ activePage: 'projects' })}
            >
              Projects
            </Nav.Link>
            <Nav.Link
              href='/#careers'
              className={
                'nav-link' +
                (this.state.activePage == 'careers' ? ' active' : '')
              }
              onClick={() => this.setState({ activePage: 'careers' })}
            >
              Careers
            </Nav.Link>
            <Nav.Link
              href='/#news'
              className={
                'nav-link' + (this.state.activePage == 'news' ? ' active' : '')
              }
              onClick={() => this.setState({ activePage: 'news' })}
            >
              News
            </Nav.Link>
            <Nav.Link
              href='/#contactUs'
              className={
                'nav-link' +
                (this.state.activePage == 'contact' ? ' active' : '')
              }
              onClick={() => this.setState({ activePage: 'contact' })}
            >
              Contact us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default Header
