export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS'
export const GET_PROJECTS_FAIL = 'GET_PROJECTS_FAIL'
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS'
export const GET_PROJECT_FAIL = 'GET_PROJECT_FAIL'
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS'
export const GET_CLIENTS_FAIL = 'GET_CLIENTS_FAIL'
export const GET_FILTER_LIST_SUCCESS = 'GET_FILTER_LIST_SUCCESS'
export const GET_FILTER_LIST_FAIL = 'GET_FILTER_LIST_FAIL'
export const GET_WHAT_WE_DO_SUCCESS = 'GET_WHAT_WE_DO_SUCCESS'
export const GET_WHAT_WE_DO_FAIL = 'GET_WHAT_WE_DO_FAIL'
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS'
export const GET_NEWS_FAIL = 'GET_NEWS_FAIL'
export const GET_NEWS_FILTER_SUCCESS = 'GET_NEWS_FILTER_SUCCESS'
export const GET_NEWS_FILTER_FAIL = 'GET_NEWS_FILTER_FAIL'
export const GET_PEOPLE_SUCCESS = 'GET_PEOPLE_SUCCESS'
export const GET_PEOPLE_FAIL = 'GET_PEOPLE_FAIL'
export const GET_SLIDER_SUCCESS = 'GET_SLIDER_SUCCESS'
export const GET_SLIDER_FAIL = 'GET_SLIDER_FAIL'
export const GET_SCOPES_SUCCESS = 'GET_SCOPES_SUCCESS'
export const GET_SCOPES_FAIL = 'GET_SCOPES_FAIL'
export const GET_SECTORS_SUCCESS = 'GET_SECTORS_SUCCESS'
export const GET_SECTORS_FAIL = 'GET_SECTORS_FAIL'
export const GET_LATEST_WORK_SUCCESS = 'GET_LATEST_WORK_SUCCESS'
export const GET_LATEST_WORK_FAIL = 'GET_LATEST_WORK_FAIL'
export const GET_ALL_CAREERS_SUCCESS = 'GET_ALL_CAREERS_SUCCESS'
export const GET_ALL_CAREERS_FAIL = 'GET_ALL_CAREERS_FAIL'
export const POST_INTERN_SHIP_SUCCESS = 'POST_INTERN_SHIP_SUCCESS'
export const POST_INTERN_SHIP_FAIL = 'POST_INTERN_SHIP_FAIL'
export const POST_FULLTIME_POSITION_SUCCESS = 'POST_FULLTIME_POSITION_SUCCESS'
export const POST_FULLTIME_POSITION_FAIL = 'POST_FULLTIME_POSITION_FAIL'
