import React, { Component } from 'react'
// import { Container, Row, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import {
  getSliderAction,
  getScopesAction,
  getLatestWorkAction,
} from '../../Redux/Actions/data.actions'
import Loader from 'react-loader-spinner'
import SimpleSlider from '../../Components/Slider/slider.component'
import FooterCustomer from '../../Components/Footer/footer.customer'
import HomeCard from '../../Components/Card/home.card.component'
import HomeAboutUs from './content/aboutUS'
import LastProjects from './content/lastProject'
import './home.styles.css'

const loader = {
  margin: '3rem',
  transition: '3s',
}

class HomePage extends Component {
  componentDidMount() {
    this.props.getSlider()
    this.props.getScopes()
    this.props.getLatestWork()
  }

  render() {
    const { loading, slider, latestWork, scopes } = this.props
    if (loading) {
      return (
        <Loader
          type='Oval'
          color='#010039'
          height={100}
          width={100}
          style={loader}
        />
      )
    }
    return (
      <section className='home-container'>
        {/* <HeaderPhoto /> */}
        <SimpleSlider sliderData={slider} />
        <HomeCard />
        <HomeAboutUs />
        <LastProjects />
        <FooterCustomer />
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  scopes: state.scopes,
  slider: state.slider,
  loading: state.loading,
  latestWork: state.latestWork,
})

const mapDispatchToProps = (dispatch) => ({
  getSlider: () => dispatch(getSliderAction()),
  getScopes: () => dispatch(getScopesAction()),
  getLatestWork: () => dispatch(getLatestWorkAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
