import React, { Component } from 'react'

import img1 from './images/5.png'
import img2 from './images/4.png'
import img3 from './images/3.png'
import img4 from './images/2.png'
import img5 from './images/1.png'
import img6 from './images/6.png'

class HomeCard extends Component {
  render() {
    return (
      <div className='container-fluid mb-5'>
        <div className='row'>
          <div className='col-6 order-1 order-md-0 col-md-4 col-xl-2 p-2 c3 c d-flex align-items-center '>
            <div>
              <img
                src={img1}
                className='img-fluid card-img-top size mb-2'
                alt='...'
              />
              <h3 className='font-weight-bold card-title'>30+</h3>
              <h5 className='card-title mb-n2'>
                Years in the
                <br />
                Market
              </h5>
            </div>
          </div>
          <div className='col-6 order-0 order-md-1 col-md-4 col-xl-2 p-2 c1 c d-flex align-items-center text-white'>
            <div>
              <img
                src={img2}
                className='img-fluid card-img-top size mb-2'
                alt='...'
              />
              <h3 className='font-weight-bold card-title text-white'>
                12,000+
              </h3>
              <h5 className='card-title'>Buildings</h5>
            </div>
          </div>
          <div className='col-6 order-2 order-md-2 col-md-4 col-xl-2 p-2 c3 c d-flex align-items-center'>
            <div>
              <img
                src={img3}
                className='img-fluid card-img-top size mb-2'
                alt='...'
              />
              <h3 className='font-weight-bold card-title'>250+</h3>
              <h5 className='card-title'>Experts</h5>
            </div>
          </div>
          <div className='col-6 order-3 order-md-3 col-md-4 col-xl-2 p-2 c1 c d-flex align-items-center text-white'>
            <div>
              <img
                src={img4}
                className='img-fluid card-img-top size mb-2'
                alt='...'
              />
              <h3 className='font-weight-bold card-title text-white'>200+</h3>
              <h5 className='card-title'>Projects</h5>
            </div>
          </div>
          <div className='col-6 order-5 order-md-4 col-md-4 col-xl-2 p-2 c3 c d-flex align-items-center'>
            <div>
              <img
                src={img5}
                className='img-fluid card-img-top size mb-2'
                alt='...'
              />
              <h3 className='font-weight-bold card-title'>100+</h3>
              <h5 className='card-title'>Clients</h5>
            </div>
          </div>
          <div className='col-6 order-4 order-md-5 col-md-4 col-xl-2 p-2 c1 c d-flex align-items-center'>
            <div>
              <img
                src={img6}
                className='img-fluid card-img-top size mb-2'
                alt='...'
              />
              <h3 className='font-weight-bold card-title text-white'>
                10,000+
              </h3>
              <h5 className='card-title mb-n2'>
                Acres
                <br />
                Developed
              </h5>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HomeCard
