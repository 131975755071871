import {
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAIL,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  GET_FILTER_LIST_SUCCESS,
  GET_FILTER_LIST_FAIL,
  GET_WHAT_WE_DO_SUCCESS,
  GET_WHAT_WE_DO_FAIL,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAIL,
  GET_NEWS_FILTER_SUCCESS,
  GET_NEWS_FILTER_FAIL,
  GET_PEOPLE_SUCCESS,
  GET_PEOPLE_FAIL,
  GET_SLIDER_SUCCESS,
  GET_SLIDER_FAIL,
  GET_SCOPES_SUCCESS,
  GET_SCOPES_FAIL,
  GET_LATEST_WORK_SUCCESS,
  GET_LATEST_WORK_FAIL,
} from '../Actions/data.actions-types'

const INITIAL_STATE = {
  projects: [],
  project: [],
  clients: [],
  filterList: [],
  whatWeDo: [],
  news: [],
  newsFilter: [],
  people: [],
  slider: [],
  scopes: [],
  loading: true,
  searchTerm: '',
  filters: [],
  latestWork: [],
}

const dataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
        loading: false,
      }
    case GET_PROJECTS_FAIL:
      return {
        ...state,
      }
    case GET_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.payload,
        loading: false,
      }
    case GET_PROJECT_FAIL:
      return {
        ...state,
      }
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload,
        loading: false,
      }
    case GET_CLIENTS_FAIL:
      return {
        ...state,
      }
    case GET_FILTER_LIST_SUCCESS:
      return {
        ...state,
        filterList: action.payload,
        loading: false,
      }
    case GET_FILTER_LIST_FAIL:
      return {
        ...state,
      }
    case GET_WHAT_WE_DO_SUCCESS:
      return {
        ...state,
        whatWeDo: action.payload,
        loading: false,
      }
    case GET_WHAT_WE_DO_FAIL:
      return {
        ...state,
      }
    case GET_NEWS_SUCCESS:
      return {
        ...state,
        news: action.payload,
        loading: false,
      }
    case GET_NEWS_FAIL:
      return {
        ...state,
      }
    case GET_NEWS_FILTER_SUCCESS:
      return {
        ...state,
        newsFilter: action.payload,
        loading: false,
      }
    case GET_NEWS_FILTER_FAIL:
      return {
        ...state,
      }
    case GET_PEOPLE_SUCCESS:
      return {
        ...state,
        people: action.payload,
        loading: false,
      }
    case GET_PEOPLE_FAIL:
      return {
        ...state,
      }
    case GET_SLIDER_SUCCESS:
      return {
        ...state,
        slider: action.payload,
        loading: false,
      }
    case GET_SLIDER_FAIL:
      return {
        ...state,
      }
    case GET_SCOPES_SUCCESS:
      return {
        ...state,
        scopes: action.payload,
      }
    case GET_SCOPES_FAIL:
      return {
        ...state,
      }
    case GET_LATEST_WORK_SUCCESS:
      return {
        ...state,
        latestWork: action.payload,
      }
    case GET_LATEST_WORK_FAIL:
      return {
        ...state,
      }
    default:
      return state
  }
}

export default dataReducer
