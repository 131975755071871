import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import PageHeader from '../../Components/PageHeader/pageHeader.component'

class AboutDma extends Component {
  constructor() {
    super()
    this.state = {
      pageHeader: {
        title: 'About DMA',
      },
    }
  }

  render() {
    return (
      <div>
        <PageHeader data={this.state.pageHeader} />
        <Container fluid>{/* <img src={History} alt="About" /> */}</Container>
      </div>
    )
  }
}

export default AboutDma
