import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import './projectDetails.styles.css'
import { Link } from 'react-router-dom'

const Projectdetails = (props) => {
  let scopes = []
  let scopeIDs = []
  let sectors = []
  let sectorIDs = []

  const {
    title,
    description,
    city,
    country,
    scope,
    scope_id,
    sector,
    sector_id,
    client,
    size,
    duration,
    location,
  } = props.data

  console.log(sector, 'sector')
  if (scope) {
    scopeIDs = scope_id.split(',')
    scopes = scope.split(',')
    scopes = scopes.map((value, id) => {
      return (
        <Link
          // className='text-secondary'
          style={{ color: '#939598' }}
          to={`/Projects/scope/${scopeIDs[id]}`}
        >
          {value}
        </Link>
      )
    })
  }

  if (sector) {
    sectorIDs = sector_id.split(',')
    sectors = sector.split(',')
    sectors = sectors.map((value, id) => {
      return (
        <Link
          // className='text-secondary'
          style={{ color: '#939598' }}
          to={`/Projects/sector/${sectorIDs[id]}`}
        >
          {value}
        </Link>
      )
    })
  }

  return (
    <div className='text-left'>
      <div className='project-heading mt-3'>
        <h1 style={{ color: 'rgb(24, 30, 64)' }}>{title}</h1>
        <p>{city + ', ' + country}</p>
      </div>
      <div className='container mt-5  '>
        <div className='row'>
          <div className='col-12 col-sm-3 m-0 p-0'>
            <div className='data-line '>
              <span style={{ color: '#d98032' }}>Client:</span>
              <br />
              <p style={{ fontSize: '1rem', color: '#939598' }}>{client}</p>
            </div>
            <div className='data-line '>
              <span style={{ color: '#d98032' }}>Sector:</span>
              <br />
              <p style={{ fontSize: '1rem', color: '#939598' }}>
                {sectors.map((value, id) => {
                  return (
                    <div
                      className='aside-text'
                      style={{ color: '#939598' }}
                      key={id}
                    >
                      {value}
                    </div>
                  )
                })}
              </p>
            </div>
            <div className='data-line '>
              <span style={{ color: '#d98032' }}>Scope:</span>
              <br />
              <p style={{ fontSize: '1rem', color: '#939598' }}>
                {scopes.map((value, id) => {
                  return (
                    <div
                      className='aside-text'
                      style={{ color: '#939598' }}
                      key={id}
                    >
                      {value}
                    </div>
                  )
                })}
              </p>
            </div>
            <div className='data-line '>
              <span style={{ color: '#d98032' }}>Project Size:</span>
              <br />
              <p style={{ fontSize: '1rem', color: '#939598' }}>{size}</p>
            </div>

            {location ? (
              <div className='data-line '>
                <span style={{ color: '#d98032' }}>Location:</span>
                <br />
                <p style={{ fontSize: '1rem', color: '#939598' }}>{location}</p>
              </div>
            ) : null}
          </div>
          <div
            className='col-12 col-sm-1'
            style={{ borderRight: '2px solid #d98032' }}
          />
          <div className='col-12 col-sm-8  mt-5 mt-sm-0 pt-md-3' style={{}}>
            <p className='text-justify' style={{ color: '#939598' }}>
              {ReactHtmlParser(description)}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Projectdetails
