import React, { Component } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import TopImage from './images/Our-History.jpg'

class FullTimePosition extends Component {
  constructor() {
    super()
  }

  render() {
    return (
      <Container>
        <Row className='mt-5 pt-5'>
          <Col md={6} className='order-0 order-md-1'>
            <img src={TopImage} alt='Overview' style={{ width: '100vw' }} />
          </Col>
          <Col md={6} className={'mt-5 mt-md-0 order-1 order-md-0'}>
            <Row>
              <Col md={12} className={''}>
                {/* <div className={'orangeBg'} style={{ width: 40 }}></div> */}
                {/* <Col md={12}> */}
                <p className={'h1 b text-left mb-5'}>OUR HISTORY</p>
                {/* </Col> */}
              </Col>
            </Row>
            <Row>
              <Col md={12} className={'  mt-2 mt-md-0 '}>
                <p className={'fs-5 b  p-grey text-justify '}>
                  DMA was established in 1989, as a diversified architectural
                  firm as the cornerstone for the DMG foundation: one of the
                  leading entities in Real Estate Development in Egypt.
                </p>
                <p className={'fs-5 b p-grey text-justify'}>
                  DMA enjoys being a strong Engineering consultancy that
                  provides solutions to our clients whether it is design,
                  engineering services, or supervision with it is approach to
                  projects delivering. In a collaboration between the staff
                  members, a project is materialized from concept to design.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default FullTimePosition
