import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import Header from './Components/Header/header.component'
import Footer from './Components/Footer/footer.component'
import Home from './Pages/HomePage/home.page'
import About from './Pages/AboutPage/about.page'
import History from './Pages/HistoryPage/history.page'
import Careers from './Pages/CareersPage/careers.page'
import ApplicationForm from './Pages/CareersPage/applicationForm.page'
import Projects from './Pages/ProjectsPage/projects.page'
import ProjectSingle from './Pages/ProjectPage/project.page'
import PeopleList from './Pages/PeopleListPage/peopleList.page'
import PeopleSingle from './Pages/PeopleSinglePage/peopleSingle.page'
import News from './Pages/NewsPage/news.page'
import NewsSingle from './Pages/NewsSinglePage/newsSingle.page'
import Clients from './Pages/ClientsPage/clients.page'
// import Collaboration from "./Pages/CollaborationPage/collaboration.page";
import WhatWeDo from './Pages/WhatWeDoPage/whatWeDo.page'
import ContactUs from './Pages/ContactUsPage/contactUs.page'
import { getWhatWeDoAction } from './Redux/Actions/data.actions'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      projects: {
        cards: [],
        filterList: [],
        loading: true,
      },
      newsList: {
        pageHeader: {
          id: 1,
          title: 'The core of our business',
          breadcrumb: '',
          description: '',
        },
        breadCrumbs: {
          id: 1,
          path: ['News'],
        },
        news: [],
        filterList: [],
        loading: 'true',
      },
      whatWeDo: [],
    }
  }

  getMainData = () => {
    let newstate = this.state
    newstate.projects.loading = true
    this.setState(newstate)
  }

  componentDidMount() {
    this.getMainData()
  }

  render() {
    return (
      <div>
        <Header data={this.state} />
        <div className='App'>
          <Switch>
            <Route
              exact
              path='/'
              render={(props) => (
                <Home data={{ projects: this.state.projects }} />
              )}
            />
            <Route exact path='/Projects/:id' component={ProjectSingle} />
            <Route exact path='/Projects' component={Projects} />
            <Route
              exact
              path='/Projects/:filterType/:id'
              component={Projects}
            />
            <Route exact path='/People/' component={PeopleList} />
            <Route exact path='/PeopleSingle/' component={PeopleSingle} />
            <Route exact path='/News/' component={News} />
            <Route exact path='/ContactUs/' component={ContactUs} />
            <Route exact path='/AboutDma/' component={About} />
            <Route exact path='/History/' component={History} />
            <Route exact path='/Careers/' component={Careers} />
            {/* <Route exact path="/Collaboration/" component={Collaboration} /> */}
            <Route exact path='/WhatWeDo/' component={WhatWeDo} />
            <Route exact path='/NewsSingle/' component={NewsSingle} />
            <Route exact path='/OurClients/' component={Clients} />
            <Route exact path='/ApplicationForm/' component={ApplicationForm} />
          </Switch>
        </div>
        <Footer />
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    whatWeDo: state.whatWeDo,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getWhatWeDo: () => dispatch(getWhatWeDoAction()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App)
