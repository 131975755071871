export const ourServices = [
  {
    title: 'ARCHITECTURE',
    description:
      'With our extensive experience in all types of buildings. We provide complete architecture services by combining architectural vision with multidisciplinary site selection, planning, engineering, and interior design expertise. Applying green architecture concepts to help sustain the environment and improve the way of living.',
  },
  {
    title: 'INTERIOR',
    description:
      'With our extensive experience in all types of buildings. We provide complete architecture services by combining architectural vision with multidisciplinary site selection, planning, engineering, and interior design expertise. Applying green architecture concepts to help sustain the environment and improve the way of living.',
  },
  {
    title: 'MASTER PLANNING & LANDSCAPE',
    description:
      'We believe that Master planning must be both visionary and practical to achieve full development potential in addition to understanding the financial cost and phasing implications. We explore landscape planning in a combination of both hard and soft zones. Our landscape architects play an integral role throughout the design process, they shape environments that connect people to each other and to the world around them, in addition to blending an imaginative design gestures with natural systems to establish a sense of place.',
  },
  {
    title: 'Structure',
    description:
      'The structure team is engaged from the early stages of design, helping the architects choose the optimum structural solutions that serve the clients vision and the design intent while keeping economy and cost efficiency in perspective. The team at DMA is hand-picked to fit certain criteria including, but not limited to, the knowledge of international codes, sharp engineering skills and a drive for continuous learning. Members of the structural department have acquired a wide range of expertise in conventional and non-conventional structural solutions and always strive for knowledge of emerging trends in their field.',
  },
  {
    title: 'MEP & INFRASTRUCTURE',
    description:
      'Our approach integrates designing environmental and economic challenges to reduce costs and provide long-term, sustainable solutions while preparing our clients objectives, Our Engineering teams from Infrastructure and MEP supports clients through all stages of the project by collaborating with our architectural colleagues to enhance the project’s vision, we contribute to create high-performance projects, We are always mindful to our client’s needs.',
  },
  {
    title: 'Construction Supervision',
    description:
      'DMA construction supervision team understand construction methodologies from every level which allows them to provide highly qualified, accurate, and time-efficient supervision services to support and enhance project execution, making sure to deliver with quality, schedule and budget, Our goal is to help clients make confident decisions more consistently, easily and quickly, and to meet their expectations.',
  },
]
