import React, { Component } from 'react'
import './footer.css'
import imgc1 from './images/c1.png'
import imgc2 from './images/c2.png'
import imgc3 from './images/c3.png'
import imgc4 from './images/c4.png'
import imgc5 from './images/c5.png'
import imgc6 from './images/c6.png'
import logos from './images/dmaaaaa.jpg'

class FooterCustomer extends Component {
  render() {
    return (
      <footer>
        <div className='footer-content'>
          {/* <div className='border'></div> */}
          <h3 className='dark-blue-color bottom-border-h'>Our Customers</h3>
          <img src={logos} alt='logos' style={{ width: '70%' }} />
          {/* <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing edivt, sed diam
          </p> */}
          {/* <ul className='socials d-flex justify-content-evenly'> */}
          {/* <div className='d-flex flex-row justify-content-evenly'>
            <div>
              <img className='img' width={200} src={imgc4}></img>
            </div>
            <div>
              <img className='img' width={100} src={imgc6}></img>
            </div>
            <div>
              <img className='img' width={100} src={imgc5}></img>
            </div>
            <div>
              <img className='img' width={100} src={imgc3}></img>
            </div>
            <div>
              <img className='img' width={100} src={imgc2}></img>
            </div>
            <div>
              <img className='img' width={100} src={imgc1}></img>
            </div>
          </div> */}
          {/* </ul> */}
        </div>
      </footer>
    )
  }
}

export default FooterCustomer
