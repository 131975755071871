import React, { Component } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import SecondImage from './images/group-diverse-people-having-business-meeting.jpg'
import ThirdImage from './images/hands-engineer-working-blueprint-construction-concept-engineering-tools-vintage-tone-retro-filter-effect-soft-focus-selective-focus-(3).jpg'
import FourthImage from './images/image-engineering-objects-workplace-top-view-construction-concept-engineering-tools-vintage-tone-retro-filter-effect-soft-focus-selective-focus-(3).jpg'
import missions from './images/mission-vision.jpg'
import FifithImage from './images/history_5.png'
import SixthImage from './images/history_6.png'
import SeventhImage from './images/history_7.png'
import './about.styles.css'
import { ourServices } from './staticData'

class OverViewPage extends Component {
  render() {
    return (
      <div className='about-overview'>
        {/* header */}

        {/* mann */}
        <Container>
          {/* <div
            // className={'my-5'}
            style={{
              backgroundImage: `url(${SecondImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              //   padding: '2rem',
              height: '60vh',
              // width: '100vw',
            }}
          ></div> */}
          {/* How we work ++ TWO PICS*/}
          <Row className='mt-5 pt-5'>
            <Col md={6} className='order-0 order-md-1'>
              <img
                src={SecondImage}
                alt='Overview'
                style={{ width: '100vw' }}
              />
            </Col>
            <Col md={6} className={'mt-5 mt-md-0 order-1 order-md-0'}>
              <Row>
                <Col md={12} className={'  '}>
                  {/* <div className={'orangeBg'} style={{ width: 40 }}></div> */}
                  {/* <Col md={12}> */}
                  <p className={'h1 b text-left mb-5     '}>HOW WE WORK</p>
                  {/* </Col> */}
                </Col>
              </Row>
              <Row>
                <Col md={12} className={'  mt-2 mt-md-0 '}>
                  <p className={'fs-5 b  p-grey text-justify '}>
                    We go deep in life to envisage the usage of the designed
                    facility or development on hand Design reflects clearly the
                    need and requirements of the facility user whether it is a
                    mixed-use development, a residential complex, or an office
                    building, we visualized and design it “ inside-out”
                  </p>
                  <p className={'fs-5 b p-grey text-justify'}>
                    DMA family comprises over 250 professionals and
                    administrations creating an integrated network of true
                    talents that make DMA distinctive in what they offer. At
                    DMA, we believe that architectural Design Is not only about
                    being iconic or unique but about bringing the project to
                    life.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className='row'>
            <div className='col-md-6'>
              <img src={ThirdImage} alt='Overview' style={{ width: '100vw' }} />
            </div>
            <div className='col-md-6 '>
              <img
                src={FourthImage}
                alt='Overview'
                style={{ width: '100vw' }}
              />
            </div>
          </div>

          <Row>
            <img src={missions} alt='missions' width={1400} />
          </Row>

          <Row className={'mt-5 justify-content-start'}>
            <Col md={9} className={'row  pt-5'}>
              <Row>
                <Col className=' justify-content-start'>
                  <h1 className='text-left     fs-4 m-0 p-0 dark-blue-color '>
                    WHAT DMA CAN PROVIDE
                  </h1>
                </Col>
              </Row>
              <Col className={'mt-5  text-left'}>
                <p className='p-grey text-justify'>
                  DMA has in-house design and engineering team members in 8
                  different disciplines, which allow sharing of a diverse range
                  of experience, we have the capabilities and resources to lead
                  projects of nearly any scale. Our multidisciplinary,
                  standalone support is based on your individual needs by
                  providing different design and engineering services.
                </p>
              </Col>
            </Col>
          </Row>

          <Row className={'mt-5 justify-content-start'}>
            <Col md={9} className={'row  pt-5'}>
              <Row>
                <Col className='  '>
                  <h1 className='text-left     fs-4 m-0 p-0 dark-blue-color '>
                    DMA CONSULTANCY SERVICE
                  </h1>
                </Col>
              </Row>
              <Col className={'mt-5  text-left'}>
                <p className='p-grey text-justify'>
                  Concept Design Schematic Design and Body Detailed Design
                  Drawings Tender Drawings / Documents Tender Evaluation
                  Construction Drawings.
                </p>
              </Col>
            </Col>
          </Row>

          {/* Our Services */}

          {/* Our Services  Sections*/}
          <div className='row mt-5'>
            {ourServices.map((service, id) => {
              return (
                <div key={id} className='col-12 col-md-6 my-2'>
                  <h1 className='service left-border-h1 text-left fs-5 m-0 p-0 pl-3 pl-md-3  mb-5 dark-blue-color align-middle text-uppercase'>
                    {service.title}
                  </h1>
                  <div className='text-left fs-6'>
                    <p className='p-grey text-justify'>{service.description}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </Container>
      </div>
    )
  }
}

export default OverViewPage
