import React from 'react'
import { Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'animate.css/animate.min.css'
import './card.styles.css'

const Card = ({ info }) => {
  let { id, outer_image, year, title, sector, short_description, city } = info
  return (
    <Col xs={12} sm={6} lg={4} className='project_block item-block p-2'>
      <Link
        style={linkStyle}
        to={{
          pathname: `/Projects/${id}`,
          state: info,
        }}
      >
        <div className='card-container' title={title}>
          <img
            src={outer_image}
            alt='project'
            className='card-img'
            // id={id}
          />
          <div className='card-img-overlay'>
            {/* <div className="item-trans"> */}
            {/* <div className="top-card text-left">
              <div>
                <span style={{ color: "white" }}>{year}</span>
              </div>
              <hr className="mb-2" />
            </div> */}
            <div className='card-text bottom-card text-center'>
              <div>
                <h5>{title.toUpperCase()}</h5>
                <hr className='border' />
                {/* <span>{sector}</span> */}
                {/* <p>{short_description}</p> */}
                <h5 className='mt-3'>{city.toUpperCase()}</h5>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </Link>
    </Col>
  )
}
let linkStyle = {
  textDecoration: 'none',
  color: '#fff',
}
export default Card
