import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SliderCustomPaging from '../../Components/SliderCustomPaging/sliderCustomPaging.component'
import Projectdetails from '../../Components/ProjectDetails/projectDetails.component'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { getProjectAction } from '../../Redux/Actions/data.actions'

const ProjectSingle = (props) => {
  const { id } = useParams()
  const [project] = useSelector((state) => state.project)
  const dispatch = useDispatch()
  console.log(project)

  useEffect(() => {
    dispatch(getProjectAction(id))
  }, [])

  if (!project) return <div>Loading...</div>
  return (
    <section className='project-info mb-5 mt-3'>
      <Container fluid>
        <Row>
          <Col md={12}>
            <SliderCustomPaging data={project} />
          </Col>
        </Row>
        <Container>
          <Row>
            <Col md={12}>
              <Projectdetails data={project} />
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  )
}

export default ProjectSingle
