import {
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAIL,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  GET_FILTER_LIST_SUCCESS,
  GET_FILTER_LIST_FAIL,
  GET_WHAT_WE_DO_SUCCESS,
  GET_WHAT_WE_DO_FAIL,
  GET_NEWS_SUCCESS,
  GET_NEWS_FAIL,
  GET_NEWS_FILTER_SUCCESS,
  GET_NEWS_FILTER_FAIL,
  GET_PEOPLE_SUCCESS,
  GET_PEOPLE_FAIL,
  GET_SLIDER_SUCCESS,
  GET_SLIDER_FAIL,
  GET_SCOPES_SUCCESS,
  GET_SCOPES_FAIL,
  GET_SECTORS_SUCCESS,
  GET_SECTORS_FAIL,
  GET_LATEST_WORK_SUCCESS,
  GET_LATEST_WORK_FAIL,
  GET_ALL_CAREERS_SUCCESS,
  GET_ALL_CAREERS_FAIL,
  POST_FULLTIME_POSITION_SUCCESS,
  POST_FULLTIME_POSITION_FAIL,
  POST_INTERN_SHIP_SUCCESS,
  POST_INTERN_SHIP_FAIL,
} from './data.actions-types'
import {
  getProjects,
  getProject,
  getClients,
  getFilterList,
  getWhatWeDo,
  getNews,
  getNewsFilter,
  getPeople,
  getSlider,
  getScopes,
  getSectors,
  getLatestWork,
  getAllCareers,
  postFullTimePosition,
  postInternShip,
} from '../../Services/data.service'

// GET PROJECTS ACTIONS
export function getProjectsAction(
  term = null,
  filters = null,
  scopeId = null,
  sectorId = null
) {
  return function (dispatch) {
    return getProjects()
      .then((res) => {
        let projects = res.data
        if (scopeId) {
          projects = projects.filter((project) => {
            return project.scope_id && project.scope_id.includes(scopeId)
          })
        }
        if (sectorId) {
          projects = projects.filter((project) => {
            return project.scope_id && project.sector_id.includes(sectorId)
          })
        }
        if (filters) {
          projects = projects.filter((project) => {
            for (let key in filters) {
              if (project[key] === undefined || project[key] !== filters[key])
                return false
            }
            return true
          })
        }
        if (term) {
          projects = projects.filter((project) => {
            return project.title.toLowerCase().includes(term)
          })
        }
        dispatch(getProjectsActionSuccess(projects))
      })
      .catch((err) => dispatch(getProjectsActionFail(err)))
  }
}

function getProjectsActionSuccess(projects) {
  return {
    type: GET_PROJECTS_SUCCESS,
    payload: projects,
  }
}

function getProjectsActionFail(error) {
  return {
    type: GET_PROJECTS_FAIL,
    payload: error,
  }
}

// GET ONE PROJECT ACTION
export function getProjectAction(id) {
  return function (dispatch) {
    return getProject(id)
      .then((res) => dispatch(getProjectSuccess(res.data)))
      .catch((err) => dispatch(getProjectFail(err)))
  }
}

function getProjectSuccess(project) {
  return {
    type: GET_PROJECT_SUCCESS,
    payload: project,
  }
}

function getProjectFail(error) {
  return {
    type: GET_PROJECT_FAIL,
    payload: error,
  }
}

// GET CLIENTS LIST ACTIONS
export function getClientsAction() {
  return function (dispatch) {
    return getClients()
      .then((res) => dispatch(getClientsSuccess(res.data)))
      .catch((err) => dispatch(getClientsFail(err)))
  }
}

function getClientsSuccess(clients) {
  return {
    type: GET_CLIENTS_SUCCESS,
    payload: clients,
  }
}

function getClientsFail(error) {
  return {
    type: GET_CLIENTS_FAIL,
    payload: error,
  }
}

// GET FILTER LIST ACTIONS
export function getFilterListAction() {
  return function (dispatch) {
    return getFilterList()
      .then((res) => dispatch(getFilterListSuccess(res.data)))
      .catch((err) => dispatch(getFilterListFail(err)))
  }
}

function getFilterListSuccess(filterList) {
  return {
    type: GET_FILTER_LIST_SUCCESS,
    payload: filterList,
  }
}

function getFilterListFail(error) {
  return {
    type: GET_FILTER_LIST_FAIL,
    payload: error,
  }
}

// GET What We Do ACTIONS
export function getWhatWeDoAction() {
  return function (dispatch) {
    return getWhatWeDo()
      .then((res) => dispatch(getWhatWeDoSuccess(res.data)))
      .catch((err) => dispatch(getWhatWeDoFail(err)))
  }
}

function getWhatWeDoSuccess(whatWeDo) {
  return {
    type: GET_WHAT_WE_DO_SUCCESS,
    payload: whatWeDo,
  }
}

function getWhatWeDoFail(error) {
  return {
    type: GET_WHAT_WE_DO_FAIL,
    payload: error,
  }
}

// GET News ACTIONS
export function getNewsAction() {
  return function (dispatch) {
    return getNews()
      .then((res) => dispatch(getNewsSuccess(res.data)))
      .catch((err) => dispatch(getNewsFail(err)))
  }
}

function getNewsSuccess(news) {
  return {
    type: GET_NEWS_SUCCESS,
    payload: news,
  }
}

function getNewsFail(error) {
  return {
    type: GET_NEWS_FAIL,
    payload: error,
  }
}

// GET News Filter ACTIONS
export function getNewsFilterAction() {
  return function (dispatch) {
    return getNewsFilter()
      .then((res) => dispatch(getNewsFilterSuccess(res.data)))
      .catch((err) => dispatch(getNewsFilterFail(err)))
  }
}

function getNewsFilterSuccess(news) {
  return {
    type: GET_NEWS_FILTER_SUCCESS,
    payload: news,
  }
}

function getNewsFilterFail(error) {
  return {
    type: GET_NEWS_FILTER_FAIL,
    payload: error,
  }
}

// GET People ACTIONS
export function getPeopleAction() {
  return function (dispatch) {
    return getPeople()
      .then((res) => dispatch(getPeopleSuccess(res.data)))
      .catch((err) => dispatch(getPeopleFail(err)))
  }
}

function getPeopleSuccess(people) {
  return {
    type: GET_PEOPLE_SUCCESS,
    payload: people,
  }
}

function getPeopleFail(error) {
  return {
    type: GET_PEOPLE_FAIL,
    payload: error,
  }
}

// GET Slider ACTIONS
export function getSliderAction() {
  return function (dispatch) {
    return getSlider()
      .then((res) => dispatch(getSliderSuccess(res.data)))
      .catch((err) => dispatch(getSliderFail(err)))
  }
}

function getSliderSuccess(sloder) {
  return {
    type: GET_SLIDER_SUCCESS,
    payload: sloder,
  }
}

function getSliderFail(error) {
  return {
    type: GET_SLIDER_FAIL,
    payload: error,
  }
}

// GET Scopes ACTIONS
export function getScopesAction() {
  return function (dispatch) {
    return getScopes()
      .then((res) => dispatch(getScopesSuccess(res.data)))
      .catch((err) => dispatch(getScopesFail(err)))
  }
}

function getScopesSuccess(scopes) {
  return {
    type: GET_SCOPES_SUCCESS,
    payload: scopes,
  }
}

function getScopesFail(error) {
  return {
    type: GET_SCOPES_FAIL,
    payload: error,
  }
}

// GET Sectors ACTIONS
export function getSectorsAction() {
  return function (dispatch) {
    return getSectors()
      .then((res) => dispatch(getSectorsSuccess(res.data)))
      .catch((err) => dispatch(getSectorsFail(err)))
  }
}

function getSectorsSuccess(sectors) {
  return {
    type: GET_SECTORS_SUCCESS,
    payload: sectors,
  }
}

function getSectorsFail(error) {
  return {
    type: GET_SECTORS_FAIL,
    payload: error,
  }
}

// GET Latest Work ACTIONS
export function getLatestWorkAction() {
  return function (dispatch) {
    return getLatestWork()
      .then((res) => dispatch(getLatestWorkSuccess(res.data)))
      .catch((err) => dispatch(getLatestWorkFail(err)))
  }
}

function getLatestWorkSuccess(work) {
  return {
    type: GET_LATEST_WORK_SUCCESS,
    payload: work,
  }
}

function getLatestWorkFail(error) {
  return {
    type: GET_LATEST_WORK_FAIL,
    payload: error,
  }
}

// GET All Careers ACTIONS
export function getAllCareersAction() {
  return function (dispatch) {
    return getAllCareers()
      .then((res) => dispatch(getAllCareersSuccess(res.data)))
      .catch((err) => dispatch(getAllCareersFail(err)))
  }
}

function getAllCareersSuccess(work) {
  return {
    type: GET_ALL_CAREERS_SUCCESS,
    payload: work,
  }
}

function getAllCareersFail(error) {
  return {
    type: GET_ALL_CAREERS_FAIL,
    payload: error,
  }
}

// POST Fulltime positio ACTIONS
export function postFullTimePositionAction(data) {
  return function (dispatch) {
    return postFullTimePosition(data)
      .then((res) => dispatch(postFullTimePositionSuccess(res.data)))
      .catch((err) => dispatch(postFullTimePositionFail(err)))
  }
}

function postFullTimePositionSuccess(work) {
  return {
    type: POST_FULLTIME_POSITION_SUCCESS,
    payload: work,
  }
}

function postFullTimePositionFail(error) {
  return {
    type: POST_FULLTIME_POSITION_FAIL,
    payload: error,
  }
}
