import React, { Component } from 'react'
import imgAboutUs from '../images/cover 01.jpg'

class HomeAboutUs extends Component {
  render() {
    return (
      <div className='cont'>
        <div className='card'>
          <div className='row no-gutters'>
            <div className='d-none d-md-block col-6 col-md-8'>
              <img
                src={imgAboutUs}
                className='img-fluid w-100 h-100'
                alt='...'
              />
            </div>
            <div className='align-items-center c3 col-12 col-md-4 p-4'>
              <div className='p-4'>
                <h3 className='card-title-center mt-5'>About us</h3>
                <p className='card-text-center p-4 dark-blue-color'>
                  DMA We was established in 1989, as a diversified architectural
                  firm and as the corner-stone for the DMG foundation; one of
                  the leading entities in Real Estate Development in Egypt.
                </p>
                <a href='/#/AboutDma' className='btn btn-primary'>
                  More about us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HomeAboutUs
