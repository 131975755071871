import React, { Component } from 'react'
import PageHeader from '../../Components/PageHeader/pageHeader.component'
import FilterNews from '../../Components/FilterNews/filterNews.component'

class News extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageHeader: {
        title: 'News',
        breadcrumb: 'News',
      },
    }
  }
  componentDidMount() {}
  render() {
    return (
      <div>
        <PageHeader data={this.state.pageHeader} />
        <FilterNews data={this.props.location.state} />
      </div>
    )
  }
}

export default News
